<template>
  <div class="create-offer">
    <b-row class="mb-10">
      <b-col>
        <span class="main-heading"
          >{{ type == "edit" ? "Edit" : "Add" }} Offer</span
        >
        <br />
        <span class="sub-heading">for {{ clientName }}</span>
      </b-col>
      <b-col
        cols="auto"
        role="button"
        @click="
          $bvModal.hide(`${type == 'edit' ? 'edit' : 'create'}-offer-modal`)
        "
        class="close"
      >
        close
      </b-col>
    </b-row>

    <form ref="form" class="form-data">
      <!-- ADD DATE -->
      <b-form-group id="fieldset" label="Add Date" label-for="add-date">
        <b-row id="add-date">
          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-start-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="from"
              label-for="start-date"
            >
              <b-form-datepicker
                id="start-date"
                v-model="startDate"
                @context="onSelectStartDate"
                :disabled="isFullMonthCampaign"
                placeholder="1/10/2020"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0">
            <b-form-group
              id="fieldset-end-date"
              label-cols-sm="4"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="to"
              label-for="end-date"
              class="label-to"
            >
              <b-form-datepicker
                id="end-date"
                v-model="endDate"
                :disabled="isFullMonthCampaign"
                @context="onSelectEndDate"
                placeholder="31/10/2020"
                locale="en"
                :min="min"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD OFFER NAME -->
      <b-row>
        <b-col class="col-6">
          <b-form-group
            id="name-fieldset"
            class="input-label"
            label="Add Name"
            label-for="name"
          >
            <b-input
              id="name"
              v-model="title"
              class="input-field custom-input"
              col="6"
              type="text"
              required
              placeholder="Name"
            >
            </b-input>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- SELECT REGION AND ADD COUPON-->
      <b-row>
        <b-col class="col-12 col-md-6">
          <b-form-group
            id="region-fieldset"
            class="input-label"
            label="Select Region(s)"
            label-for="region"
          >
            <div class="checkbox-type">
              <input
                type="checkbox"
                id="id1"
                name="checkboxtype"
                value="KUW"
                v-model="region"
              />
              <label for="id1">KUW</label>
              <input
                type="checkbox"
                id="id2"
                name="checkboxtype"
                value="KSA"
                v-model="region"
              />
              <label for="id2">KSA</label>
              <input
                type="checkbox"
                id="id3"
                name="checkboxtype"
                value="UAE"
                v-model="region"
              />
              <label for="id3">UAE</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col class="col-12 col-md-6">
          <b-form-group
            id="coupon-fieldset"
            class="input-label"
            label="Add Coupon"
            label-for="coupon"
          >
            <b-form-input
              id="target"
              v-model="coupon"
              class="input-field custom-input"
              type="text"
              required
              placeholder="AE4567"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- ADD DESCRIPTION -->
      <b-form-group
        id="description-fieldset"
        class="input-label"
        label="Add Description"
        label-for="description"
      >
        <b-textarea
          id="description"
          v-model="description"
          class="input-field custom-input"
          type="text"
          required
          placeholder="Description"
        >
        </b-textarea>
      </b-form-group>

      <!-- ADD BUTTON -->
      <b-form-group class="mt-15">
        <b-button
          :disabled="!isFormValidated || isLoading"
          class="add-btn"
          @click="createOffer"
          >{{ type == "edit" ? "Edit" : "Add" }}
          <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
#fieldset .form-group {
  margin: auto;
}
.label-to {
  .col-form-label {
    padding-left: 25px;
  }
}
@media (max-width: 640px) {
  .label-to {
    .col-form-label {
      padding-left: 5px;
    }
  }
}
.bv-no-focus-ring {
  padding-top: 0;
  padding-bottom: 0;
}
.create-offer {
  .main-heading {
    font-weight: bold;
    font-size: 22px;
    color: #000000;
  }
  .sub-heading {
    font-size: 14px;
    font-weight: 600;
    color: #939393;
  }
  .close {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    opacity: 0.36;
  }
  .form-data {
    margin-top: 30px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px;
      font-size: 16px;
    }
    input {
      height: 53px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      font-size: 16px;
      color: #000000;
    }
    label {
      align-self: center;
      font-size: 16px;
      color: #000000;
    }
    .checkbox-type {
      input[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;
        border-radius: 5px;
        border: none;
        background-color: #f3fcff;
      }
    }
    .checkbox-type {
      label {
        margin-right: 1rem;
        padding: 10px 9px;
        color: #000 !important;
        font-weight: 500;
        cursor: pointer;
        font-size: 16px;
        border-radius: 12px;
        background-color: #f3fcff !important;
        width: 70px;
        text-align: center;
      }
    }
    .checkbox-type input[type="checkbox"]:checked + label {
      background-color: #1374d6 !important;
      color: #ddf2f9 !important;
      font-weight: bold !important;
    }
    .add-btn {
      float: right;
      width: 229px;
      height: 53px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .input-info {
      color: #000000;
      font-size: 14px;
      line-height: 30px;
    }
    .range-wrapper {
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 0px 8px 8px 0px;
      color: #f3fcff;
      font-size: 16px;
      font-weight: bold;
      margin-left: 1px;
      button,
      button:hover,
      button:active,
      button:focus {
        background: black !important;
        border: none;
        color: #fff;
        font-size: 19px;
        font-weight: bold;
        height: 100%;
      }
    }
    .days-info {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #f76d67;
    }
    .offer-sec {
      height: 41px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      border-radius: 8px;
      line-height: 41px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 8px;
      cursor: pointer;
    }
    .offer-sec-active {
      background: #d3e5f7 0% 0% no-repeat padding-box;
      color: #000000;
    }
    textarea {
      height: 205px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font-size: 14 px;
      color: #000000;
    }
  }
}
</style>

<script>
import Button from "../pages/vue-bootstrap/Button.vue";
import ApiService from "../../core/services/api.service";

export default {
  name: "create-offer",
  props: ["clientName", "type", "offer"],
  components: { Button },
  data: () => ({
    startDate: "",
    startCompleteDate: null,
    endCompleteDate: null,
    min: null,
    max: null,
    endDate: "",
    coupon: "",
    region: [],
    description: "",
    title: "",
    isLoading: false,
    isFullMonthCampaign: false,
  }),
  mounted() {
    if (this.type == "edit") {
      this.startDate = this.offer.start;
      this.endDate = this.offer.end;
      this.description = this.offer.description;
      this.coupon = this.offer.coupon;
      this.region = this.offer.country;
      this.title = this.offer.title;
    }
  },
  methods: {
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.firstDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth(),
        1
      );
      this.lastDay = new Date(
        ctx.activeDate.getFullYear(),
        ctx.activeDate.getMonth() + 1,
        0
      );

      // set date min and max range
      this.min = new Date(this.firstDay);
      let today = new Date();
      // this.min.setDate(ctx.activeDate.getDate());
      this.max = new Date(this.lastDay);
      this.min =
        this.startCompleteDate && this.startCompleteDate > today
          ? this.startCompleteDate
          : today;
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      if (!this.endDate || this.isFullMonthCampaign) {
        return;
      }
      this.endCompleteDate = ctx.activeDate;
    },
    createOffer() {
      let dataToPost = {
        start: this.startDate,
        end: this.endDate,
        description: this.description,
        coupon: this.coupon,
        country: this.region,
        title: this.title,
        client_id: this.$route.params.id,
      };
      console.log("create Offer", dataToPost);
      if (this.type == "edit") {
        console.log("Edit Offer", dataToPost);
        // TODO : edit api to integrated
        ApiService.post("/offers/update", {
          ...dataToPost,
          offer_id: this.offer._id,
        })
          .then((response) => {
            this.isLoading = false;
            this.$emit("offer-created")
            this.$bvModal.hide("edit-offer-modal");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        ApiService.post("/offers", dataToPost)
          .then((response) => {
            this.isLoading = false;
            this.$emit("offer-created");
            this.$bvModal.hide("create-offer-modal");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    handleSubmit() {
      // this.isLoading = true;
      setTimeout(() => {
        // this.isLoading = false;
        this.$bvModal.hide("create-offer-modal");
      }, 2000);
    },
  },
  computed: {
    isFormValidated() {
      if (
        ((this.startDate && this.endDate) ||
          (!this.startDate && !this.endDate)) &&
        this.description &&
        this.region &&
        this.region.length
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
