<template>
  <div class="box">
    <select @change="onChange($event)">
      <option
        v-if="
          params.data.status == 'Active' || params.data.status == 'Cancelled'
        "
        :selected="Active"
        value="Active"
      >
        Active
      </option>
      <option
        v-if="params.data.status == 'Tentative'"
        :selected="Tentative"
        value="Tentative"
      >
        Tentative
      </option>

      <option
        v-if="params.data.status == 'Upcoming'"
        :selected="Upcoming"
        value="Upcoming"
      >
        Upcoming
      </option>

      <option
        v-if="params.data.status == 'Expired'"
        :selected="Expired"
        value="Expired"
      >
        Expired
      </option>

      <option
        v-if="
          params.data.status == 'Active' ||
          params.data.status == 'Tentative' ||
          params.data.status == 'Upcoming' ||
          params.data.status == 'Cancelled'
        "
        :selected="Cancelled"
        value="Cancelled"
        class="blue"
      >
        Cancelled
      </option>
    </select>
    <div v-if="New" class="send_icon">
      <inline-svg src="media/svg/icons/Files/icon_share.svg" />
    </div>
    <div class="send_icon">
      <inline-svg @click="showEditModal" src="media/action/edit.svg" />
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "CellRendererStatusDropdown",
  data() {
    return {
      Active: false,
      Tentative: false,
      Expired: false,
      New: false,
      Cancelled: false,
    };
  },
  computed: {},
  mounted() {
    this.value();
  },
  methods: {
    async onChange(event, append = false) {
      event.stopPropagation();
      event.preventDefault();

      let APIURL = "/offers/status";
      let dataToPost = [];
      let dataTest = {
        offer_id: this.params.data._id,
        status: event.target.value,
      };
      dataToPost.push(dataTest);

      console.log(APIURL);

      await ApiService.post(APIURL, dataToPost);

      this.params.context.componentParent.fetchData();

      this.$bvToast.toast(
        `Status Updated Successfully To ${event.target.value} `,
        {
          title: "Status Changed",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          variant: "success",
          appendToast: append,
        }
      );
      if (event.target.value == "Cancelled") {
        this.Cancelled = true;
      } else {
        this.Cancelled = false;
      }
    },
    showEditModal() {
      setTimeout(() => {
        this.$bvModal.show("edit-offer-modal");
      }, 100);
    },

    value() {
      if (this.params.data.status == "Expired") {
        this.Expired = true;
      } else if (this.params.data.status == "Tentative") {
        this.Tentative = true;
      } else if (this.params.data.status == "Active") {
        this.Active = true;
      } else if (this.params.data.status == "Upcoming") {
        this.Upcoming = true;
      } else if (this.params.data.status == "Cancelled") {
        this.Cancelled = true;
      }

      // this.Active = this.params.data.status;
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  font-size: inherit;
  background: rgb(89, 158, 89) !important;
  color: white !important;
}
.red {
  font-size: inherit;
  background: #fd397a !important;
  color: white !important;
}
.yellow {
  font-size: inherit;
  background: #f3a430 !important;
  color: white !important;
}
.blue {
  font-size: inherit;
  background: rgb(163, 163, 255) !important;
  color: white;
}
.box select option:focus {
  background: #aaa !important;
}
.box {
  border-radius: 5%;
  padding: 0px;
  margin: 0px;
}

.box select {
  font-size: 1rem !important;
  text-align: center;
  border: 0.1px solid #c3dcf5;
  background-color: #f3f9ff;
  border-radius: 0.5rem;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  line-height: 2.5;
  width: 90px;
}
.box:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}
.send_icon {
  position: absolute;
  margin: auto;
  height: 3rem;
  width: 3rem;
  margin-left: 0.2rem;
  text-align: center;
  display: inline-block;
}
svg {
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
}
.send_icon:hover {
  transform: scale(1.2);
}
</style>
