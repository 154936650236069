<template>
  <div>
    <div class="row">
      <div class="col-xl-8">
        <!-- <img src="media/images/adidas.svg" /> -->
        <img class="logo" :src="client_detail.logo" />
        <h1 class="ml-4 main-header">
          <b>{{ client_detail.name }} Offers</b>
        </h1>
      </div>

      <div class="col-xl-4">
        <div>
          <div style="width: fit-content" class="d-flex ml-auto text-right">
            <!-- <v-dialog
              ref="dialog"
              v-model="menu"
              :close-on-content-click="false"
              :value="calenderDate"
              transition="scale-transition"
              offset-y
              width="fit-content"
              color="#1C97C3"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  style="width:15rem;"
                  v-model="calenderDate"
                  label="Filter By Month & Year"
                  prepend-icon="mdi-calendar"
                  readonly
                  color="#1C97C3"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#1C97C3"
                v-model="calenderDate"
                type="month"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="OnCalenderDateChange">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog> -->
            <!-- <b-dropdown
              toggle-class="custom-v-dropdown btn btn-white font-weight-bold dropdown-heres"
              text="August 2020"
              style="float: right"
            >
              <b-dropdown-item>August 2020</b-dropdown-item>
              <b-dropdown-item>August 2050</b-dropdown-item>
              <b-dropdown-item>Third Action</b-dropdown-item>
            </b-dropdown> -->
            <div style="display: inline-flex">
              <!-- <input
                class="form-control"
                type="month"
                @change="dateFilter"
                :value="date"
              /> -->
              <div style="width: fit-content" class="d-flex ml-auto text-right">
                <v-menu
                  ref="dialog"
                  v-model="menu"
                  offset-y
                  :close-on-content-click="true"
                  :value="calenderDate"
                  transition="scale-transition"
                  width="fit-content"
                  color="#F64E70"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="dropdown-heres" v-bind="attrs" v-on="on">
                      {{ calenderDate | moment("MMMM") }}
                      <span>
                        <v-icon color="#F64E70"> mdi-menu-down </v-icon>
                      </span>
                    </div>
                  </template>
                  <v-date-picker
                    color="#F64E70"
                    v-model="calenderDate"
                    type="month"
                    no-title
                    @change="fetchData()"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-row>
      <b-col cols="auto pr-0" align-self="center">
        <h3 class="ml-2 offers-header"><b>Manage Offers</b></h3>
      </b-col>

      <b-col cols="auto pl-0" align-self="center">
        <v-btn
          v-b-modal.create-offer-modal
          class="mx-1 mr-4 mdi-plus-bg"
          fab
          dark
          color="gainsboro"
        >
          <v-icon class="mdi-plus-content">mdi-plus</v-icon>
        </v-btn>
      </b-col>
      <b-col class="text-right" align-self="end">
        <label for="check-button"
          ><h6 style="padding-top: 3px">
            <b>Switch to Calendar view</b>
          </h6></label
        >
        <b-form-checkbox
          name="check-button"
          v-model="show_calendar"
          size="lg"
          switch
          class="switch-to-calendar-button"
        >
        </b-form-checkbox>
      </b-col>
    </b-row>

    <div class="row mb-10">
      <div class="col-xl-12">
        <!-- <span class="rowsPerPage">
          <b-form-select
            label="Grouped options"
            @change="onPageSizeChanged"
            v-model="selectedNumberOfRows"
            :options="items"
            ><template #first>
              <b-form-select-option :value="null" disabled
                >Rows Per Page</b-form-select-option
              >
            </template></b-form-select
          >
        </span> -->
        <div class="inf-table" v-if="!show_calendar">
          <div v-if="!offers || !offers.length">
            <h1><b>No Offers created yet</b></h1>
            <h3>
              <b
                >click the
                <v-btn
                  class="mx-1 ml-4 mr-4 mdi-plus-bg2"
                  fab
                  dark
                  color="blue"
                  v-b-modal.create-offer-modal
                >
                  <v-icon class="mdi-plus-content2">mdi-plus</v-icon>
                </v-btn>
                to add a Offer</b
              >
            </h3>
          </div>
          <div style="width: 100%" v-else>
            <ag-grid-vue
              class="ag-grid-table ag-theme-material"
              :gridOptions="gridOptions"
              style="width: 100%; height: 500px"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :suppressColumnMoveAnimation="true"
              :suppressDragLeaveHidesColumns="true"
              :rowData="offers"
              :pagination="true"
              rowSelection="multiple"
              :paginationPageSize="10"
              :animateRows="true"
              @rowSelected="onSelectOffer"
              @rowClicked="onRowClicked"
            >
            </ag-grid-vue>

            <span class="rowsPerPage rowsPerpageBtm" style="width: 135px">
              <b-form-select
                label="Grouped options"
                @change="onPageSizeChanged"
                v-model="selectedNumberOfRows"
                :options="items"
                ><template #first>
                  <b-form-select-option :value="null" disabled
                    >Rows Per Page</b-form-select-option
                  >
                </template></b-form-select
              >
            </span>
            <span class="rowsPerPage rowsPerpageBtmLeft">
              <b-button
                class="cancel-btn"
                v-b-modal.cancel-offers
                :disabled="!selectedOffers.length"
                >Cancel</b-button
              >

              <b-button
                class="deleteAll-btn"
                v-b-modal.delete-offers
                :disabled="!selectedOffers.length"
                >Delete All</b-button
              >
            </span>
          </div>
        </div>
        <!-- calendar view -->
        <calendarview :offers="offers" v-if="show_calendar" />
      </div>
    </div>

    <!-- create campaign modal -->
    <b-modal
      id="create-offer-modal"
      title="Overflowing Content"
      hide-header
      hide-footer
    >
      <create-offer
        :clientName="client_detail.name"
        @offer-created="onOfferCreated"
      />
    </b-modal>

    <!-- edit offer modal -->
    <b-modal
      id="edit-offer-modal"
      title="Overflowing Content"
      hide-header
      hide-footer
    >
      <create-offer
        :clientName="client_detail.name"
        @offer-created="onOfferCreated"
        type="edit"
        :offer="selectedOffer"
      />
    </b-modal>
    <b-modal
      id="delete-offers"
      ok-variant="danger"
      ok-title="Remove"
      @ok="deleteAllConfirmation"
      size="sm"
      centered
    >
      <h5>Are you sure you want to delete all following offers?</h5>
      <ul style="margin-left: 20px">
        <li v-for="(item, i) in selectedOffers" v-bind:key="i">
          {{ item.title }}
        </li>
      </ul>
    </b-modal>

    <b-modal
      id="cancel-offers"
      ok-variant="primary"
      ok-title="Yes"
      cancel-title="No"
      @ok="setAllStatus"
      size="sm"
      centered
    >
      <h5>Are you sure you want to cancel all following offers?</h5>
      <ul style="margin-left: 20px">
        <li v-for="(item, i) in selectedOffers" v-bind:key="i">
          {{ item.title }}
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown7 from "@/view/content/dropdown/Dropdown7.vue";
import CellRendererAvatars from "@/view/pages/home/Planners/CellRendererAvatar.vue";
import CellRendererName from "@/view/pages/home/Planners/CellRendererName.vue";
import CellRendererDropdown from "@/view/pages/offers/CellRendererStatusDropdown.vue";
import CellRendererMATERIALS from "@/view/pages/home/Planners/CellRendererMATERIALS.vue";
import CellRendererCoupon from "@/view/pages/home/Planners/CellRendererCoupon.vue";
import startplan from "@/view/pages/home/Planners/startplan.vue";
import { AgGridVue } from "ag-grid-vue";
import CreateOffer from "@/view/components/create-offer";
import FormRadioVue from "../vue-bootstrap/FormRadio.vue";
import ApiService from "../../../core/services/api.service";
import calendarview from "@/view/components/offers-calendar.vue";
import CellRenderDate from "@/view/pages/CellRenderDate.vue";
export default {
  components: {
    Dropdown7,
    AgGridVue,
    CellRendererAvatars,
    CellRendererName,
    CellRendererMATERIALS,
    CellRendererCoupon,
    startplan,
    CreateOffer,
    CellRendererDropdown,
    calendarview,
    CellRenderDate,
  },
  computed: {
    ...mapGetters(["ClientGetters"]),
    client_detail() {
      return this.$store.getters.ClientGetters;
    },
  },
  data() {
    return {
      date: new Date().toISOString().substring(0, 7),
      selectedNumberOfRows: null,
      items: [5, 10, 25, 50, 100],
      pagination: [],
      searchQuery: "",
      maxPageNumbers: 10,
      calenderDate: new Date().toISOString().substr(0, 7),
      menu: false,
      gridApi: null,
      StartingPlan: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
      },
      gridOptions: {
        frameworkComponents: {
          CellRendererAvatars: CellRendererAvatars,
          CellRendererName: CellRendererName,
          CellRendererMATERIALS: CellRendererMATERIALS,
          CellRendererCoupon: CellRendererCoupon,
          CellRendererDropdown: CellRendererDropdown,
          CellRenderDate: CellRenderDate,
        },
        context: {
          componentParent: this,
        },
        rowHeight: 60,

        columnDefs: [
          {
            maxWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true,
          },
          {
            headerName: "DATES",
            field: "start",
            minWidth: 180,
            filter: true,
            cellClass: "cell-vertical-align-text-left",
            cellRendererFramework: "CellRenderDate",
          },
          {
            headerName: "NAME",
            field: "title",
            filter: true,
            cellStyle: { "white-space": "normal", "line-height": "45px" },
            autoHeight: true,
          },
          {
            headerName: "DESCRIPTION",
            field: "description",
            filter: true,
            cellStyle: { "white-space": "normal" },
            autoHeight: true,
          },
          {
            headerName: "REGION",
            field: "country",
            filter: false,
            cellClass: "cell-vertical-align-text-left",
          },
          {
            headerName: "COUPON",
            field: "coupon",
            filter: false,
            cellClass: "cell-vertical-align-text-left",
          },

          {
            headerName: "STATUS",
            field: "status",
            cellClass: "cell-vertical-align-text-left",
            cellRendererFramework: "CellRendererDropdown",
            cellStyle: { "white-space": "normal", "line-height": "30px" },

            suppressSorting: true,
          },
        ],
      },
      offers: [],
      show_calendar: false,
      selectedOffer: {},
      selectedOffers: [],
    };
  },
  mounted() {
    this.fetchData();
    // if user refresh this page directly so we've to hit api again to get client details.
    if (!this.client_detail || !this.client_detail.length) {
      this.$store.dispatch("CLIENT_ACTION", this.$route.params.id);
    }
  },
  methods: {
    onRowClicked(node) {
      console.log(node.data);
      this.selectedOffer = node.data;
    },
    onPageSizeChanged(newPageSize) {
      // var value = document.getElementById("page-size").value;
      // this.gridOptions.api.paginationSetPageSize(Number(value));
      this.gridOptions.api.paginationSetPageSize(this.selectedNumberOfRows);
    },
    OnCalenderDateChange() {
      this.$refs.dialog.save(this.calenderDate);
      console.log(moment(this.calenderDate).format("M"));
      console.log(moment(this.calenderDate).format("Y"));
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    fetchData() {
      const total = 1000;
      ApiService.get(
        `/offers?client_id=${this.$route.params.id}&total=${total}&month=${
          this.calenderDate.split("-")[1]
        }&year=${this.calenderDate.split("-")[0]}`
      ).then((response) => {
        this.offers = response.data.data.data;
        this.pagination = response.data.data;
      });
    },
    dateFilter(e) {
      this.date = e.target.value;
    },
    onOfferCreated() {
      this.selectedOffers = [];
      this.fetchData();
    },
    onSelectOffer(offer) {
      console.log("grid selected", this.gridApi.getSelectedRows());
      if (!offer.node.selected) {
        let index = this.selectedOffers.indexOf(offer.data);
        this.selectedOffers.splice(index, 1);
        return;
      }
      // this.offers.find((x) => {
      //   if (x._id === offer.data._id) {
      //     this.selectedOffers.push(offer.data);
      //     return;
      //   }
      // });
      this.selectedOffers = this.gridApi.getSelectedRows();
      console.log("selected offer array", this.selectedOffers);
    },
    async deleteAllConfirmation() {
      let payload = { offer_ids: this.selectedOffers.map((x) => x._id) };
      await ApiService.post("/offers/delete", payload);
      this.selectedOffers = [];
      this.fetchData();
    },
    async setAllStatus() {
      let payload = this.selectedOffers.map((x) => {
        return {
          offer_id: x._id,
          status: "Cancelled",
        };
      });
      await ApiService.post("/offers/status", payload);
      this.selectedOffers = [];
      this.fetchData();
    },
  },
  watch: {
    date: {
      handler: function () {
        this.fetchData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background: #f8fafc 0% 0% no-repeat padding-box;
}

::v-deep .ag-theme-material {
  .ag-ltr {
    .ag-cell {
      opacity: 0.8;
      font-size: 13px !important;
      border: none;
    }
  }
  .ag-paging-row-summary-panel {
    display: none !important;
  }
}
.rowsPerPage {
  display: inline-block !important;
}
.rowsPerpageBtm {
  position: absolute !important;
  bottom: 32px !important;
  right: 325px !important;
}
.rowsPerpageBtmLeft {
  position: absolute;
  bottom: 32px;
  left: 50px;
}

.paging-margin .ag-paging-panel {
  margin: 0 140px;
}
.offers-header {
  font-family: "Poppins";
}
.v-btn--fab.v-size--default {
  height: 40px;
  width: 40px;
}
.deleteAll-btn {
  background: #1c97c3 !important;
  float: right !important;
  color: white !important;
  width: 120px !important;
  text-transform: uppercase;
}
.cancel-btn {
  float: left !important;
  background: #bdd8f5 !important;
  color: #1c97c3 !important;
  width: 100px !important;
  margin-right: 10px;
  text-transform: uppercase;
}
.logo {
    height: 85px;
    width: 80px;
    border-radius: 20px;
    border: 2px solid white;
    box-shadow: gainsboro 0px 0px 5px;
}
</style>
