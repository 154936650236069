<template>
  <v-app id="inspire">
    <v-row class="fill-height">
      <v-col lg="3" md="3">
        <div class="tab">
          <span class="title">Allocation</span>
          <div class="box">
            <div>
              <input type="checkbox" class="allocation_check" />
              <span>1-8 October 2020</span>
            </div>
            <div>
              <input type="checkbox" class="allocation_check" />
              <span>1-8 October 2020</span>
            </div>
            <div>
              <input type="checkbox" class="allocation_check" />
              <span>1-8 October 2020</span>
            </div>
            <div>
              <input type="checkbox" class="allocation_check" />
              <span>1-8 October 2020</span>
            </div>
            <!-- <label class="container">Four
                <input type="checkbox">
                <span class="checkmark"></span>
              </label> -->
          </div>
        </div>
        <div class="tab">
          <span class="title">Type of ADS</span>
          <div class="box">
            <div>
              <input type="checkbox" class="type_check" />
              <span>Micro influencer</span>
            </div>
            <div>
              <input type="checkbox" class="type_check" />
              <span>Micro influencer</span>
            </div>
            <div>
              <input type="checkbox" class="type_check" />
              <span>Photo Ads</span>
            </div>
          </div>
        </div>
        <div class="tab">
          <span class="title">Status</span>
          <div class="box">
            <div>
              <input type="checkbox" class="status_check" />
              <span>New</span>
            </div>
            <div>
              <input type="checkbox" class="status_check" />
              <span>Confirm</span>
            </div>
          </div>
        </div>
      </v-col>
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "offers-calendar-view",

  props: ["offers"],
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue"
      ],
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      const events = [];
      for (let i = 0; i < this.offers.length; i++) {
        events.push({
          name: `${
            this.offers[i].title
              ? this.offers[i].title
              : this.offers[i].description
          }`,
          start: new Date(this.offers[i].start),
          end: new Date(this.offers[i].end),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: false,
        });
      }
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style lang="scss">
// .v-calendar-weekly__day-label {
//   .v-btn--fab {
//     background: red;
//   }
// }
// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
//   background-color: transparent !important;
//   float: right !important;
//   pointer-events: none !important;
// }
// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover {
//   background-color: transparent !important;
// }
// .v-present {
//   .v-calendar-weekly__day-label {
//     .v-btn--fab {
//       font-size: 1.5rem !important;
//     }
//   }
// }
.v-calendar {
  .v-event {
    text-align: center;
  }
}
.tab {
  margin-bottom: 10%;
  .title {
    font-weight: 600;
    margin-bottom: 1%;
  }
  .box {
    padding: 1.5rem;
    box-shadow: 0px 0px 10px 1px #d0cccc;
    border-radius: 10px;
    margin-top: 1rem;
    div {
      padding-bottom: 1.5rem;
    }
    input {
      margin-right: 1rem;
    }
    span {
      font-weight: 700;
    }
  }
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
